#search-list {
  position: relative;
  transition: all 2s ease;

  .divider {
    height: 1px;
    margin-left: 5px;
    background: rgba(50, 52, 62, 0.67);
    margin: 0 12px;
  }

  ul {
    position: fixed;
    background: white;
    z-index: 2000;
    border: 1px solid rgb(216, 216, 216);
    width: 540px;
    box-shadow: 0 1px 5px 0 rgba(8, 35, 48, 0.2);
    max-height: 400px;
    overflow: auto;

    li {
      margin: 0;
      cursor: pointer;
      transition: all 0.2s ease;
      font-size: 16px;
      color: #32343e;
      font-weight: bold;
      padding: 12px 0 12px 12px;

      // border: 1px solid red;

      &:hover {
        background: rgb(216, 216, 216);
      }
    }
  }
}
